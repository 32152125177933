import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const roleService = service
  .enhanceEndpoints({ addTagTypes: ['Role'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRoles: builder.query<Res['roles'], Req['getRoles']>({
        providesTags: [{ id: 'LIST', type: 'Role' }],
        query: () => ({
          url: `partners/roles`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getRoles(
  store: any,
  data: Req['getRoles'],
  options?: Parameters<typeof roleService.endpoints.getRoles.initiate>[1],
) {
  return store.dispatch(roleService.endpoints.getRoles.initiate(data, options))
}
// END OF FUNCTION_EXPORTS

export const {
  useGetRolesQuery,
  // END OF EXPORTS
} = roleService

/* Usage examples:
const { data, isLoading } = useGetRolesQuery({ id: 2 }, {}) //get hook
const [createRoles, { isLoading, data, isSuccess }] = useCreateRolesMutation() //create hook
roleService.endpoints.getRoles.select({id: 2})(store.getState()) //access data from any function
*/
