import { FC, useState } from 'react'
import ActiveLink from 'components/base/ActiveLink'
import cx from 'classnames'
import { logout, useUser } from 'common/services/useUser'
import Dropdown from 'components/base/forms/Dropdown'
import DropdownItem from 'components/base/forms/DropdownItem'
import { getStore } from 'common/store'
import { useRouter } from 'next/router'
type ComponentType = {}

const Index: FC<ComponentType> = ({}) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const { data } = useUser({})
  const router = useRouter()
  return (
    <div className='container-fluid pt-4 pb-0 mb-0'>
      <Dropdown
        dropdownClassName={'right'}
        dropdownContent={
          <>
            <DropdownItem
              onClick={() => {
                router.push('/account')
              }}
            >
              Account Settings
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                router.push('/')
                logout(getStore(), {})
              }}
              className='text-danger'
            >
              Logout
            </DropdownItem>
          </>
        }
        show={isActive}
        setShow={setIsActive}
      >
        <div
          onClick={() => setIsActive(!isActive)}
          className='d-none cursor-pointer d-md-flex justify-content-end align-items-center gap-3'
        >
          <div className='text-end'>
            <div className='fw-semibold lh-1 fs-lg'>{data?.partner_name}</div>
            <div className='text-dark300'>{data?.email}</div>
          </div>
          <span className='fa fa-chevron-down' />
        </div>
      </Dropdown>
    </div>
  )
}

export default Index
