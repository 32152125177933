import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import Logo from 'components/Logo'
import { useLogoutMutation, useUser } from 'common/services/useUser'
import Link from 'next/link'
import ActiveLink from 'components/base/ActiveLink'
import Router, { useRouter } from 'next/router'
import cognito from 'common/cognito'
import Icon from 'project/Icon'

type AsideType = {}

type AsideNavItemType = {
  icon: string
  className?: string
  active?: boolean
}

const AsideNavItem: FC<AsideNavItemType> = ({
  active,
  children,
  className,
  icon,
}) => {
  return (
    <div
      className={classNames(
        'flex-row rounded animated aside__item fw-semibold aside__item cursor-pointer align-items-center',
        { 'active': active },
        className,
      )}
    >
      <div className='icon-container'>
        {typeof icon === 'string' ? <span className={icon} /> : icon}
      </div>
      <div className='ms-2 flex-1 content no-animation'>{children}</div>
    </div>
  )
}

const Aside: FC<AsideType> = ({}) => {
  const { data } = useUser({})
  const [logout] = useLogoutMutation()
  const [collapsed, setCollapsed] = useState(true)
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (!collapsed) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [collapsed])
  useEffect(() => {
    if (!expanded) {
      document.body.classList.add('aside-collapsed')
    } else {
      document.body.classList.remove('aside-collapsed')
    }
  }, [expanded])
  useEffect(() => {
    if (!collapsed) {
      window.scrollTo({ top: 0 })
    }
  }, [collapsed])
  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setCollapsed(true)
    })
  }, [])
  const router = useRouter()
  return (
    <div
      className={classNames('aside-fixed animated', {
        expanded,
        open: !collapsed,
      })}
    >
      <div className='d-flex px-5 py-2 d-md-none nav align-items-center justify-content-between'>
        <button
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          className='d-md-none navbar-toggler'
          type='button'
        >
          {!collapsed ? (
            <Icon fill={'body'} width={24} height={16} name='close' />
          ) : (
            <Icon fill={'body'} width={24} height={24} name='hamburger-menu' />
          )}
        </button>
        <Logo />
        <div />
      </div>
      <div
        className={classNames('aside h-100 w-100 d-flex flex-column py-5', {
          'd-none d-md-flex flex-column': collapsed,
        })}
      >
        <div className='flex-1'>
          <Link href={'/'}>
            <div
              style={{ marginLeft: -3, width: 230 }}
              className='px-5 overflow-hidden d-none d-md-block mt-0 mb-5 cursor-pointer'
            >
              <Logo />
            </div>
          </Link>
          <div className={classNames('gap-4 px-5 d-flex flex-column gap-2')}>
            <ActiveLink
              match={/(^\/(^\/)*$|customers.*)/}
              href={`/`}
              activeClassName='active'
            >
              <div>
                <AsideNavItem icon={<Icon name='dashboard' fill='body' />}>
                  Customers
                </AsideNavItem>
              </div>
            </ActiveLink>
            <ActiveLink
              match={/invoices/}
              href={`/invoices/`}
              activeClassName='active'
            >
              <div>
                <AsideNavItem icon={<Icon fill='body' name='invoices' />}>
                  Invoices
                </AsideNavItem>
              </div>
            </ActiveLink>
            <ActiveLink
              match={/\/settings.*/}
              href={`/settings/`}
              activeClassName='active'
            >
              <div>
                <AsideNavItem icon={<Icon fill='body' name='settings' />}>
                  Settings
                </AsideNavItem>
              </div>
            </ActiveLink>
          </div>
        </div>
        <div className='px-5'>
          <div
            onClick={() => {
              router.replace('/').then(() => {
                cognito.logout().finally(() => {
                  logout({})
                })
              })
            }}
          >
            <AsideNavItem icon={<Icon fill='body' name='logout' />}>
              Logout
            </AsideNavItem>
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            className='d-none cursor-pointer d-md-flex'
          >
            <svg
              className={classNames('mt-3 animate')}
              width='34'
              height='34'
              viewBox='0 0 34 34'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                className='hover-light400 bg-light300'
                width='34'
                height='34'
                rx='8'
              />
              <g
                className={classNames('animated transform-center', {
                  rotate180: expanded,
                })}
              >
                <path
                  d='M15.9367 11.2438L21.3295 16.5923C21.3835 16.6454 21.4264 16.7088 21.4557 16.7788C21.4849 16.8487 21.5 16.9238 21.5 16.9997C21.5 17.0756 21.4849 17.1507 21.4557 17.2206C21.4264 17.2906 21.3835 17.354 21.3295 17.4071L15.9367 22.7556C15.7779 22.9122 15.5641 23 15.3414 23C15.1187 23 14.905 22.9122 14.7461 22.7556C14.6682 22.6786 14.6063 22.5867 14.564 22.4854C14.5218 22.3841 14.5 22.2754 14.5 22.1656C14.5 22.0558 14.5218 21.947 14.564 21.8457C14.6063 21.7444 14.6682 21.6526 14.7461 21.5755L19.3597 16.9997L14.7461 12.4251C14.6682 12.3479 14.6064 12.256 14.5642 12.1546C14.5219 12.0533 14.5002 11.9446 14.5002 11.8347C14.5002 11.7249 14.5219 11.6161 14.5642 11.5148C14.6064 11.4135 14.6682 11.3215 14.7461 11.2444C14.905 11.0878 15.1187 11 15.3414 11C15.5641 11 15.7779 11.0872 15.9367 11.2438Z'
                  fill='#6E6E73'
                />
                <path
                  d='M13.0262 12.6828L16.8782 16.6942C16.9168 16.7341 16.9474 16.7816 16.9683 16.8341C16.9892 16.8865 17 16.9429 17 16.9998C17 17.0567 16.9892 17.113 16.9683 17.1655C16.9474 17.2179 16.9168 17.2655 16.8782 17.3053L13.0262 21.3167C12.9128 21.4342 12.7601 21.5 12.601 21.5C12.442 21.5 12.2893 21.4342 12.1758 21.3167C12.1201 21.2589 12.0759 21.19 12.0457 21.114C12.0155 21.0381 12 20.9565 12 20.8742C12 20.7918 12.0155 20.7103 12.0457 20.6343C12.0759 20.5583 12.1201 20.4894 12.1758 20.4316L15.4712 16.9998L12.1758 13.5688C12.1202 13.5109 12.076 13.442 12.0458 13.366C12.0157 13.29 12.0001 13.2084 12.0001 13.126C12.0001 13.0437 12.0157 12.9621 12.0458 12.8861C12.076 12.8101 12.1202 12.7412 12.1758 12.6833C12.2893 12.5658 12.442 12.5 12.601 12.5C12.7601 12.5 12.9128 12.5654 13.0262 12.6828Z'
                  fill='#6E6E73'
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aside
