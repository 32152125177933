import { Res, VerifyAccount } from 'common/types/responses'
import { Req } from 'common/types/requests'
import cognito from 'common/cognito'
import { getApi } from 'common/api'
import { service } from 'common/service'
import { getStore } from 'common/store'
import { getRoles, useGetRolesQuery } from './useRole'

export const userService = service
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<Res['user'], Req['getUser']>({
        providesTags: ['User'],
        queryFn: async (args, _, _2, baseQuery) => {
          try {
            await cognito.getSession()
            const query = await baseQuery({
              method: 'GET',
              url: `auth/partners/me`,
            })
            await getRoles(getStore(), {}) // always get roles first since we rely on permissions
            return query
          } catch (e) {
            return { data: null }
          }
        },
      }),
      login: builder.mutation<{}, Req['login']>({
        query: (query: Req['login']) => ({
          body: query,
          method: 'POST',
          url: `auth/partners/`,
        }),
      }),

      logout: builder.mutation<{}, Req['logout']>({
        queryFn: async (args, { dispatch }) => {
          try {
            await cognito.logout()
          } catch (e) {}
          await dispatch(service.util.resetApiState())
          return { data: {} }
        },
      }),

      updateUser: builder.mutation<Res['user'], Req['updateUser']>({
        invalidatesTags: () => ['User'],
        query: (query: Req['updateUser']) => ({
          body: query,
          method: 'POST',
          url: `auth/partners/me/user-settings`,
        }),
      }),

      verifyAccount: builder.mutation<{}, Req['verifyAccount']>({
        query: (query: Req['verifyAccount']) => {
          return {
            body: query,
            method: 'POST',
            url: `auth/partners/otp`,
          }
        },
        transformResponse: async (res: VerifyAccount, _, req) => {
          try {
            await logout(getStore(), { skipClearDevSettings: true })
          } catch (e) {}
          await cognito.loginWithToken(req.email, res)
          await getUser(getStore(), {}, { forceRefetch: true })
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })
export const useUser = userService.endpoints.getUser.useQueryState
export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useUpdateUserMutation,
  useVerifyAccountMutation,
  // END OF EXPORTS
} = userService

export async function logout(
  store: any,
  data: Req['logout'],
  options?: Parameters<typeof userService.endpoints.logout.initiate>[1],
) {
  store.dispatch(userService.endpoints.logout.initiate(data, options))
  return Promise.all(store.dispatch(userService.util.getRunningQueriesThunk()))
}

export async function getUser(
  store: any,
  data: Req['getUser'],
  options?: Parameters<typeof userService.endpoints.getUser.initiate>[1],
) {
  store.dispatch(userService.endpoints.getUser.initiate(data, options))
  return store.dispatch(userService.util.getRunningQueriesThunk())
}

// Call this on resolving login, if the login action returns a 401, treat it as an unconfirmed email
export const tryConfirmEmailRedirect = function (res: any) {
  if (res?.error?.status === 401) {
    cognito.getSession().then((session) => {
      const email = session.getIdToken().payload?.email
      getApi().confirmEmailRedirect(email)
    })
  }
}

export const usePermission = (permission: 'ADMIN' | 'READ' | 'WRITE') => {
  const { data: user } = useUser({})
  const { data: roles } = useGetRolesQuery({})

  const role = roles?.find((v) => v.id === user?.role)?.name

  const permissionMap = {
    'ADMIN': role === 'PARTNER_ADMIN',
    'READ': true,
    'WRITE': role === 'PARTNER_ADMIN' || role === 'USER_WRITE',
  }

  return permissionMap[permission] || false
}
