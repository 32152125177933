import { useSelector } from 'react-redux'
import { StoreStateType } from 'common/store'

const selectHydrated = (state: StoreStateType) => {
  // @ts-ignore
  return state._persist?.rehydrated
}

export const useHydrated = () => {
  return useSelector(selectHydrated)
}
