export function parseError(e?: any) {
  if (typeof e === 'string') {
    return e
  }
  if (typeof e?.data === 'string') {
    return e.data
  }
  if (typeof e?.data?.message === 'string') {
    return e.data?.message
  }
  if (typeof e?.data?.detail === 'string') {
    return e.data?.detail
  }
  if (e?.status) {
    return `Error: ${e.status}`
  }

  const message = e?.message?.message || e?.message
  if (typeof message === 'string') {
    return message
  }
  return 'An unknown error occurred'
}
