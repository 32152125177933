import { FC, useEffect } from 'react'
import { useFlags } from 'flagsmith/react'

type DarkModeHandlerType = {}

const DarkModeHandler: FC<DarkModeHandlerType> = ({}) => {
  const flags = useFlags([], ['dark_mode'])
  useEffect(() => {
    if (flags.dark_mode) {
      document.body.setAttribute('data-bs-theme', 'dark')
    } else {
      document.body.removeAttribute('data-bs-theme')
    }
  }, [flags.dark_mode])
  return null
}

export default DarkModeHandler
