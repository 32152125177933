import 'project/polyfill'
import { AppProps } from 'next/app'
import 'styles/Bootstrap.scss'
import 'styles/Global.scss'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import NProgress from 'components/util/NProgress'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import { ToastContainer } from 'components/base/Toast'
import ProfileContainer from 'components/util/ProfileContainer'
import { Project } from 'common/project'
import Aside from 'components/nav/Aside'
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith/isomorphic'
import DarkModeHandler from 'components/DarkModeHandler'
import cognito from 'common/cognito'
import Nav from 'components/nav'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

type ComponentType = AppPropsWithLayout
cognito.init(Project.cognito)

export const defaultLayout: NextPageWithLayout['getLayout'] = (page) => {
  return (
    <>
      <Aside />
      <div className='aside-offset animated flex-fill'>
        <Nav />
        {page}
      </div>
    </>
  )
}
const AppComponent: FC<ComponentType> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || defaultLayout
  const router = useRouter()
  if (router.asPath.includes('sw.js')) {
    return null
  }
  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{ environmentID: Project.flagsmith }}
    >
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <meta name='description' content='HM Partner Portal' />
        <meta name='theme-color' content='#317EFB' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <meta name='theme-color' content='#ffffff' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
        />
        <meta property='fb:admins' content='453323778023845' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <title>Hoxton Mix Partner Portal</title>
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#42185a' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        <meta property='og:title' content='Hoxton Mix Partner Portal' />
      </Head>
      <NProgress />
      <ProfileContainer>
        {(user: any) =>
          getLayout(<Component {...pageProps} />, pageProps, user)
        }
      </ProfileContainer>
      <ToastContainer />
      <div id='modal' />
      <div id='confirm' />
      <div id='alert' />
      <DarkModeHandler />
    </FlagsmithProvider>
  )
}

export default nextReduxWrapper.withRedux(AppComponent)
