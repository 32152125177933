import { FC, useEffect } from 'react'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import { useFlagsmith } from 'flagsmith/react'
import Loader from 'components/base/Loader'

type ProfileContainerType = {}

const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data, isLoading } = useGetUserQuery({})
  const flagsmith = useFlagsmith()
  const hydrated = useHydrated()
  useEffect(() => {
    if (flagsmith.identity !== data?.id) {
      if (data?.id) {
        flagsmith.identify(`${data.id}`, { ...data } as any)
      } else {
        flagsmith.logout()
      }
    }
  }, [flagsmith, data])
  if (!hydrated || (!data && isLoading))
    return (
      <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
        <Loader />
      </div>
    )
  return <>{typeof children === 'function' ? children(data) : children}</>
}

export default ProfileContainer
